































































import { Component, Prop, Vue } from 'vue-property-decorator';
import DataTable from '@/shared/resources/components/tables/data-table/DataTable.vue';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';
import TableTemplateInterface from '@/shared/lib/interfaces/TableTemplateInterface';
import { DataTableHeader } from 'vuetify';
import DocumentItemCoreModel from '@/modules/documents/models/DocumentItemCoreModel';
import OnlineOrderItemCoreModel from '@/modules/online-orders/models/OnlineOrderItemCoreModel';
import UISettings from '@/app/lib/settings/UISettings';
import TableProductAddToCartButton from '@/app/modules/products/components/partials/tables/TableProductAddToCartButton.vue';

@Component({
  components: {
    TableProductAddToCartButton,
    DataTable,
  },
})
export default class OnlineOrdersItemsTable extends Vue {
  /**
   * Props
   */
  @Prop() private query?: ApiORMQueryBuilderExtended;
  @Prop() private items?: DocumentItemCoreModel[];

  /**
   * Data
   */
  private template: TableTemplateInterface = {
    search: false,
    topPagination: false,
    limitSelect: false,
  };

  private headers: DataTableHeader[] = [
    {
      value: 'name',
      text: 'Nazwa',
    },
    {
      value: 'quantity',
      text: 'Liczba',
    },
    {
      value: 'unit',
      text: 'Jedn.',
    },
    {
      value: 'price',
      text: 'Cena',
      class: 'table-col-pre-wrap',
    },
    {
      value: 'total',
      text: 'Suma',
      class: 'table-col-pre-wrap',
    },
    {
      value: 'cart',
      text: '',
    },
  ];

  /**
   * Methods
   */
  private openDetailsModal(productId: number) {
    UISettings.openSideModal('ProductDetails', { id: productId }, 'Szczegóły produktu');
  }

  private displayProductLink(item: OnlineOrderItemCoreModel): boolean {
    return item.isProductBased;
  }

  private displayProductCode(item: DocumentItemCoreModel): boolean {
    return !!item?.product?.code;
  }

  private displayAddToCartButton(item: OnlineOrderItemCoreModel): boolean {
    return item.isProductBased;
  }

  /**
   * Handlers
   */
  private onNameClick(productId: number) {
    this.openDetailsModal(productId);
  }
}
