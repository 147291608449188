var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DataTable',{attrs:{"headers":_vm.headers,"query":_vm.query,"items":_vm.items,"template":_vm.template,"title":"Produkty","no-search":"","no-sort":"","index-column":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [(_vm.displayProductLink(item))?_c('a',{staticClass:"font-weight-bold",on:{"click":function($event){return _vm.onNameClick(item.productId)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.displayProductCode(item))?_c('small',{staticClass:"d-block accent--text"},[_vm._v(" "+_vm._s(item.product.code)+" ")]):_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.pricesWithCurrencyFormatted.gross)+" "),_c('small',[_vm._v(_vm._s(_vm.$t('common.gross')))])]),_c('small',{staticClass:"d-block"},[_vm._v(" "+_vm._s(item.pricesWithCurrencyFormatted.net)+" "+_vm._s(_vm.$t('common.net'))+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.totalsWithCurrencyFormatted.gross)+" "),_c('small',[_vm._v(_vm._s(_vm.$t('common.gross')))])]),_c('small',{staticClass:"d-block"},[_vm._v(" "+_vm._s(item.totalsWithCurrencyFormatted.net)+" "+_vm._s(_vm.$t('common.net'))+" ")])]}},{key:"item.cart",fn:function(ref){
var item = ref.item;
return [(_vm.displayAddToCartButton(item))?_c('TableProductAddToCartButton',{attrs:{"product":item.product}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }