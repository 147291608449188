








import { Component, Prop, Vue } from 'vue-property-decorator';
import ActionsButton from '@/shared/resources/components/tables/data-table/actions/ActionsButton.vue';
import ProductCoreModel from '@/modules/products/models/ProductCoreModel';
import UISettings from '@/app/lib/settings/UISettings';

@Component({
  components: {
    ActionsButton,
  },
})
export default class TableProductAddToCartButton extends Vue {
  /**
   * Props
   */
  @Prop() private product!: ProductCoreModel;

  /**
   * Getters
   */
  private get icon(): string {
    return 'add_shopping_cart';
  }

  /**
   * Display getters
   */
  private get displayButton(): boolean {
    if (!this.product.maxCartItemAmount) {
      return false;
    }

    return this.product.maxCartItemAmount > 0;
  }

  /**
   * Methods
   */
  private openModal() {
    UISettings.openSideModal('ProductAddToCart', { productId: this.product.id }, 'Dodaj do koszyka', 'small');
  }

  /**
   * Handlers
   */
  private onButtonClick() {
    this.openModal();
  }
}
